<template>
  <div id="app" v-if="authCheck == true">
    <!-- <MainNavBar></MainNavBar> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import MainNavBar from './components/MainNavBar.vue'
import { signIn } from './authentication/index';


export default {
  name: 'App',
  data() {
    return {
      authCheck: false
    };
  },
  components: {
    // MainNavBar
  },
  async mounted() {
    await signIn()
    this.authCheck = true
  }
}
</script>

<style>
#app {
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
</style>
